import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const uploadTemplateApi = {
  namespaced: true,

  state: {
    // template
    template: null,

    // templateResponse
    templateResponse: null,

    // templateData
    templateData: [],

    usersTemplateData: [],

    clientsTemplateData: [],

    clients: [],

    users: [],

    // templateType
    templateType: null,
  },

  mutations: {
    // setTemplate
    setTemplate(state, payload) {
      state.template = payload;
    },

    // setTemplateResponse
    setTemplateResponse(state, payload) {
      state.templateResponse = payload;
    },

    // setTemplateData
    setTemplateData(state, payload) {
      state.templateData = payload;
    },

    setUsersTemplateData(state, payload) {
      state.usersTemplateData = payload;
    },

    setClientsTemplateData(state, payload) {
      state.clientsTemplateData = payload;
    },

    setUsers(state, payload) {
      state.users = payload;
    },

    setClients(state, payload) {
      state.clients = payload;
    },

    // setTemplateType
    setTemplateType(state, payload) {
      state.templateType = payload;
    },

    // keep clients and users in state
    // resetState
    resetState(state) {
      // template
      state.template = null;

      // templateResponse
      state.templateResponse = null;

      // templateData
      state.templateData = [];

      // templateType
      state.templateType = null;
    },

    fullResetState(state) {
      // template
      state.template = null;

      // templateResponse
      state.templateResponse = null;

      // templateData
      state.templateData = [];

      // templateType
      state.templateType = null;

      state.clients = [];

      state.users = [];
    },
  },

  actions: {
    // uploadUsersTemplateReq
    async uploadUsersTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // uploadUsersTemplateRes;
        const uploadUsersTemplateRes =
          await apiHelperFactory.uploadUsersTemplate(payload.body);

        // const uploadUsersTemplateRes =
        //   await apiHelperFactoryForBank.createBulkUpload(payload.body);

        return uploadUsersTemplateRes;
      } catch (error) {
        // error
        return error;
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getTemplate
    getTemplate(state) {
      return state.template;
    },

    // getTemplateResponse
    getTemplateResponse(state) {
      return state.templateResponse;
    },

    // getTemplateData
    getTemplateData(state) {
      return state.templateData;
    },

    // getTemplateType
    getTemplateType(state) {
      return state.templateType;
    },

    getUsers(state) {
      return state.users;
    },

    getClients(state) {
      return state.clients;
    },

    // getDataTableHeadings
    getDataTableHeadings(state) {
      // Check if templateData is not empty and contains valid headers
      if (!state.templateData.length || !state.templateData[0].length) {
        return [];
      }

      // Extract headers
      const headers = state.templateData[0];

      // Filter out null or undefined headers
      // const filteredHeaders = headers.filter(
      //   (header) => header !== null && header !== undefined
      // );

      // return filteredHeaders;
      return headers;
    },

    // getDataTableRows
    getDataTableRows(state) {
      // Check if templateData has more than one row
      if (state.templateData.length <= 1) {
        return [];
      }

      // Exclude the first row (headings) and return the rest
      // const rows = state.templateData.slice(1);

      // Extract headers and determine the number of columns
      const numColumns = state.templateData[0].length;

      // Extract rows and adjust each row to match the number of columns
      const rows = state.templateData.slice(1).map((row) => {
        // Create a new row with extra empty columns if necessary
        const adjustedRow = [...row];

        // Add empty columns if the row has fewer columns than the header
        while (adjustedRow.length < numColumns) {
          adjustedRow.push(null); // or null if you prefer null values
        }

        return adjustedRow;
      });

      return rows;
    },

    // getUsersDataTable
    getUsersDataTable(state) {
      // Check if templateData is not empty and contains valid headers
      if (!state.templateData.length || !state.templateData[0].length) {
        return [];
      }

      // Extract headers
      const headers = state.templateData[0];

      // Check that headers contain necessary fields
      const officeNameIndex = headers.indexOf("Office Name *");
      const staffNameIndex = headers.indexOf("Staff Name");
      const usernameIndex = headers.indexOf("User name");
      const firstNameIndex = headers.indexOf("First name *");
      const lastNameIndex = headers.indexOf("Last name *");
      const emailIndex = headers.indexOf("Email *");
      const autoGenPasswordIndex = headers.indexOf("Auto Gen. Password");
      const overridePWPolicyIndex = headers.indexOf(
        "Override pw expiry policy"
      );
      const roleNameIndex = headers.indexOf(
        "Role Name *(Enter in consecutive cells horizontally)"
      );

      if (
        officeNameIndex === -1 ||
        staffNameIndex === -1 ||
        usernameIndex === -1 ||
        firstNameIndex === -1 ||
        lastNameIndex === -1 ||
        emailIndex === -1 ||
        autoGenPasswordIndex === -1 ||
        overridePWPolicyIndex === -1 ||
        roleNameIndex === -1
      ) {
        return [];
      }

      // mapping of the template data into the memberDataTable format
      return state.templateData
        .slice(1)
        .map((data, index) => {
          // Check if data for current member is valid
          if (!data || data.length < headers.length) {
            return null; // or any other value that makes sense in your case
          }

          return {
            id: index + 1,
            memberName: data[firstNameIndex],
            memberSurname: data[lastNameIndex],
            memberId: null,
            officeName: data[officeNameIndex],
            staffName: data[staffNameIndex],
            email: data[emailIndex],
            autoGenPassword: data[autoGenPasswordIndex],
            overridePWPolicy: data[overridePWPolicyIndex],
            roleName: data[roleNameIndex],
            contribution: null,
            loanAmount: null,
            loanPremium: null,
            loanBalance: null,
            status: "Error",
            opened: false,
            editable: false,
            selected: false,
          };
        })
        .filter(Boolean); // This will remove all 'null' values from the resulting array
    },

    // getUsersEdits
    getUsersEdits(state, getters) {
      const usersData = getters.getUsersDataTable;
      const edits = [];

      for (let i = 0; i < usersData.length; i++) {
        const user = usersData[i];
        const rowNumber = i + 2;

        edits.push({
          cellAddress: `A${rowNumber}`,
          newCellValue: user.officeName || null,
        });
        edits.push({
          cellAddress: `B${rowNumber}`,
          newCellValue: user.memberId || null,
        });
        edits.push({
          cellAddress: `C${rowNumber}`,
          newCellValue: user.email || null,
        });
        edits.push({
          cellAddress: `D${rowNumber}`,
          newCellValue: user.memberName || null,
        });
        edits.push({
          cellAddress: `E${rowNumber}`,
          newCellValue: user.memberSurname || null,
        });
        edits.push({
          cellAddress: `F${rowNumber}`,
          newCellValue: user.email || null,
        });
        edits.push({
          cellAddress: `G${rowNumber}`,
          newCellValue: user.autoGenPassword || false,
        });
        edits.push({
          cellAddress: `H${rowNumber}`,
          newCellValue: user.overridePWPolicy || false,
        });
        edits.push({ cellAddress: `I${rowNumber}`, newCellValue: null });
        edits.push({
          cellAddress: `J${rowNumber}`,
          newCellValue: user.roleName || "Member",
        });
      }

      return edits;
    },
  },
};

export default uploadTemplateApi;
