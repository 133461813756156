import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const savingsAccountApi = {
  namespaced: true,

  state: {
    // savingsAccount
    savingsAccount: {},

    // walletSavingsAccount
    walletSavingsAccount: {},

    // contributionSavingsAccount
    contributionSavingsAccount: {},

    // savingsAccountForClient
    savingsAccountForClient: {},

    // walletSavingsAccountForClient
    walletSavingsAccountForClient: {},

    // contributionSavingsAccountForClient
    contributionSavingsAccountForClient: {},

    // savingsAccounts
    savingsAccounts: [],
  },

  mutations: {
    // setSavingsAccount
    setSavingsAccount(state, payload) {
      state.savingsAccount = payload;
    },

    // setWalletSavingsAccount
    setWalletSavingsAccount(state, payload) {
      state.walletSavingsAccount = payload;
    },

    // setContributionSavingsAccount
    setContributionSavingsAccount(state, payload) {
      state.contributionSavingsAccount = payload;
    },

    // setSavingsAccountForClient
    setSavingsAccountForClient(state, payload) {
      state.savingsAccountForClient = payload;
    },

    // setWalletSavingsAccountForClient
    setWalletSavingsAccountForClient(state, payload) {
      state.walletSavingsAccountForClient = payload;
    },

    // setContributionSavingsAccountForClient
    setContributionSavingsAccountForClient(state, payload) {
      state.contributionSavingsAccountForClient = payload;
    },

    // setSavingsAccounts
    setSavingsAccounts(state, payload) {
      state.savingsAccounts = payload;
    },

    // resetState
    resetState(state) {
      // savingsAccount
      state.savingsAccount = {};

      // walletSavingsAccount
      state.walletSavingsAccount = {};

      // contributionSavingsAccount
      state.contributionSavingsAccount = {};

      // savingsAccountForClient
      state.savingsAccountForClient = {};

      // walletSavingsAccountForClient
      state.walletSavingsAccountForClient = {};

      // contributionSavingsAccountForClient
      state.contributionSavingsAccountForClient = {};

      // savingsAccounts
      state.savingsAccounts = [];
    },
  },

  actions: {
    // getSavingsAccountsReq
    async getSavingsAccountsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSavingsAccountsRes
        const getSavingsAccountsRes =
          await apiHelperFactory.getSavingsAccounts();

        // setSavingsAccounts
        commit("setSavingsAccounts", getSavingsAccountsRes.data);

        return getSavingsAccountsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getSavingsAccountWithAssociationsReq
    async getSavingsAccountWithAssociationsReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSavingsAccountRes
        const getSavingsAccountRes = await apiHelperFactory.getSavingsAccount(
          payload.savingsAccountId,
          {
            associations: "all",
          }
        );

        // setSavingsAccount
        commit("setSavingsAccount", getSavingsAccountRes.data);

        return getSavingsAccountRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getWalletSavingsAccountWithAssociationsReq
    async getWalletSavingsAccountWithAssociationsReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getWalletSavingsAccountRes
        const getWalletSavingsAccountRes =
          await apiHelperFactory.getSavingsAccount(payload.savingsAccountId, {
            associations: "all",
          });

        // setWalletSavingsAccount
        commit("setWalletSavingsAccount", getWalletSavingsAccountRes.data);

        return getWalletSavingsAccountRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getContributionSavingsAccountWithAssociationsReq
    async getContributionSavingsAccountWithAssociationsReq(
      { commit },
      payload
    ) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getContributionSavingsAccountRes
        const getContributionSavingsAccountRes =
          await apiHelperFactory.getSavingsAccount(payload.savingsAccountId, {
            associations: "all",
          });

        // setContributionSavingsAccount
        commit(
          "setContributionSavingsAccount",
          getContributionSavingsAccountRes.data
        );

        return getContributionSavingsAccountRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getSavingsAccountWithAssociationsForClientReq
    async getSavingsAccountWithAssociationsForClientReq(
      { commit },
      payload = {}
    ) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getSavingsAccountForClientRes
        const getSavingsAccountForClientRes =
          await apiHelperFactory.getSavingsAccount(payload.savingsAccountId, {
            associations: "all",
          });

        // setSavingsAccountForClient
        commit(
          "setSavingsAccountForClient",
          getSavingsAccountForClientRes.data
        );

        return getSavingsAccountForClientRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getWalletSavingsAccountWithAssociationsForClientReq
    async getWalletSavingsAccountWithAssociationsForClientReq(
      { commit },
      payload
    ) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getWalletSavingsAccountForClientRes
        const getWalletSavingsAccountForClientRes =
          await apiHelperFactory.getSavingsAccount(payload.savingsAccountId, {
            associations: "all",
          });

        // setWalletSavingsAccountForClient
        commit(
          "setWalletSavingsAccountForClient",
          getWalletSavingsAccountForClientRes.data
        );

        return getWalletSavingsAccountForClientRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getContributionSavingsAccountWithAssociationsForClientReq
    async getContributionSavingsAccountWithAssociationsForClientReq(
      { commit },
      payload = {}
    ) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getContributionSavingsAccountForClientRes
        const getContributionSavingsAccountForClientRes =
          await apiHelperFactory.getSavingsAccount(payload.savingsAccountId, {
            associations: "all",
          });

        // setContributionSavingsAccountForClient
        commit(
          "setContributionSavingsAccountForClient",
          getContributionSavingsAccountForClientRes.data
        );

        return getContributionSavingsAccountForClientRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // submitNewSavingsApplicationReq
    async submitNewSavingsApplicationReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.submitNewSavingsApplication(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // approveSavingsApplicationReq
    async approveSavingsApplicationReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.approveSavingsApplication(
          payload.body,
          payload.accountId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // activateSavingsAccountReq
    async activateSavingsAccountReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.activateSavingsAccount(
          payload.body,
          payload.accountId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // withdrawalTransactionReq
    async withdrawalTransactionReq({ commit }, { payload, accountId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.withdrawalTransaction(payload, accountId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // closeSavingsAccountReq
    async closeSavingsAccountReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // closeSavingsAccount
        return await apiHelperFactory.closeSavingsAccount(
          payload.body,
          payload.accountId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // deleteSavingsAccountReq
    async deleteSavingsAccountReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // deleteSavingsAccount
        return await apiHelperFactory.deleteSavingsAccount(payload.accountId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getSavingsAccount
    getSavingsAccount(state) {
      return state.savingsAccount;
    },

    // getSavingsAccountTransactions
    getSavingsAccountTransactions(state) {
      if (
        state.savingsAccount &&
        "transactions" in state.savingsAccount &&
        Array.isArray(state.savingsAccount.transactions) &&
        state.savingsAccount.transactions.length > 0
      ) {
        return state.savingsAccount.transactions;
      }

      return [];
    },

    // getWalletSavingsAccount
    getWalletSavingsAccount(state) {
      return state.walletSavingsAccount;
    },

    // getWalletSavingsAccountTransactions
    getWalletSavingsAccountTransactions(state) {
      if (
        state.walletSavingsAccount &&
        "transactions" in state.walletSavingsAccount &&
        Array.isArray(state.walletSavingsAccount.transactions) &&
        state.walletSavingsAccount.transactions.length > 0
      ) {
        return state.walletSavingsAccount.transactions;
      }

      return [];
    },

    // getWalletSavingsAccountBalance
    getWalletSavingsAccountBalance(state) {
      if (
        state.walletSavingsAccount &&
        "transactions" in state.walletSavingsAccount &&
        Array.isArray(state.walletSavingsAccount.transactions) &&
        state.walletSavingsAccount.transactions.length > 0
      ) {
        // Find the transaction with the highest id
        const latestTransaction =
          state.walletSavingsAccount.transactions.reduce(
            (max, transaction) => (transaction.id > max.id ? transaction : max),
            state.walletSavingsAccount.transactions[0]
          );

        // runningBalance
        return latestTransaction.runningBalance;
      }

      return 0;
    },

    // getContributionSavingsAccountBalance
    getContributionSavingsAccountBalance(state) {
      console.log(
        "getContributionSavingsAccountBalance",
        state.contributionSavingsAccount
      );
      if (
        state.contributionSavingsAccount &&
        "transactions" in state.contributionSavingsAccount &&
        Array.isArray(state.contributionSavingsAccount.transactions) &&
        state.contributionSavingsAccount.transactions.length > 0
      ) {
        // Find the transaction with the highest id
        const latestTransaction =
          state.contributionSavingsAccount.transactions.reduce(
            (max, transaction) => (transaction.id > max.id ? transaction : max),
            state.contributionSavingsAccount.transactions[0]
          );

        // runningBalance
        return latestTransaction.runningBalance;
      }

      return 0;
    },

    // getContributionSavingsAccount
    getContributionSavingsAccount(state) {
      return state.contributionSavingsAccount;
    },

    // getContributionSavingsAccountTransactions
    getContributionSavingsAccountTransactions(state) {
      if (
        state.contributionSavingsAccount &&
        "transactions" in state.contributionSavingsAccount &&
        Array.isArray(state.contributionSavingsAccount.transactions) &&
        state.contributionSavingsAccount.transactions.length > 0
      ) {
        return state.contributionSavingsAccount.transactions;
      }

      return [];
    },
    // getContributionSavingsAccountTransactions
    getContributionSavingsAccountTransactionsDeposit(state) {
      let SavingsArray = [];
      if (
        state.contributionSavingsAccount &&
        "transactions" in state.contributionSavingsAccount &&
        Array.isArray(state.contributionSavingsAccount.transactions) &&
        state.contributionSavingsAccount.transactions.length > 0
      ) {
        SavingsArray = state.contributionSavingsAccount.transactions;
      }

      const groupDeposit = SavingsArray.filter(
        (saving) => saving.transactionType.deposit === true
      );

      const totalContributionAmount = groupDeposit.reduce(
        (sum, method) => sum + method.amount,
        0
      );

      return totalContributionAmount;
    },

    // getSavingsAccountForClient
    getSavingsAccountForClient(state) {
      return state.savingsAccountForClient;
    },

    // getSavingsAccountTransactionsForClient
    getSavingsAccountTransactionsForClient(state) {
      if (
        state.savingsAccountForClient &&
        "transactions" in state.savingsAccountForClient &&
        Array.isArray(state.savingsAccountForClient.transactions) &&
        state.savingsAccountForClient.transactions.length > 0
      ) {
        return state.savingsAccountForClient.transactions;
      }

      return [];
    },

    // getWalletSavingsAccountForClient
    getWalletSavingsAccountForClient(state) {
      return state.walletSavingsAccountForClient;
    },

    // getWalletSavingsAccountTransactionsForClient
    getWalletSavingsAccountTransactionsForClient(state) {
      if (
        state.walletSavingsAccountForClient &&
        "transactions" in state.walletSavingsAccountForClient &&
        Array.isArray(state.walletSavingsAccountForClient.transactions) &&
        state.walletSavingsAccountForClient.transactions.length > 0
      ) {
        return state.walletSavingsAccountForClient.transactions;
      }

      return [];
    },

    // getWalletSavingsAccountBalanceForClient
    getWalletSavingsAccountBalanceForClient(state) {
      if (
        state.walletSavingsAccountForClient &&
        "transactions" in state.walletSavingsAccountForClient &&
        Array.isArray(state.walletSavingsAccountForClient.transactions) &&
        state.walletSavingsAccountForClient.transactions.length > 0
      ) {
        // Find the transaction with the highest id
        const latestTransaction =
          state.walletSavingsAccountForClient.transactions.reduce(
            (max, transaction) => (transaction.id > max.id ? transaction : max),
            state.walletSavingsAccountForClient.transactions[0]
          );

        // runningBalance
        return latestTransaction.runningBalance;
      }

      return 0;
    },

    // getContributionSavingsAccountBalanceForClient
    getContributionSavingsAccountBalanceForClient(state) {
      if (
        state.contributionSavingsAccountForClient &&
        "transactions" in state.contributionSavingsAccountForClient &&
        Array.isArray(state.contributionSavingsAccountForClient.transactions) &&
        state.contributionSavingsAccountForClient.transactions.length > 0
      ) {
        // Find the transaction with the highest id
        const latestTransaction =
          state.contributionSavingsAccountForClient.transactions.reduce(
            (max, transaction) => (transaction.id > max.id ? transaction : max),
            state.contributionSavingsAccountForClient.transactions[0]
          );

        // runningBalance
        return latestTransaction.runningBalance;
      }

      return 0;
    },

    // getContributionSavingsAccountForClient
    getContributionSavingsAccountForClient(state) {
      return state.contributionSavingsAccountForClient;
    },

    // getContributionSavingsAccountTransactionsForClient
    getContributionSavingsAccountTransactionsForClient(state) {
      if (
        state.contributionSavingsAccountForClient &&
        "transactions" in state.contributionSavingsAccountForClient &&
        Array.isArray(state.contributionSavingsAccountForClient.transactions) &&
        state.contributionSavingsAccountForClient.transactions.length > 0
      ) {
        return state.contributionSavingsAccountForClient.transactions;
      }

      return [];
    },

    // getSavingsAccounts
    getSavingsAccounts(state) {
      return state.savingsAccounts;
    },

    // getFirstSavingsAccount
    getFirstSavingsAccount(state) {
      return state.savingsAccounts.length > 0 ? state.savingsAccounts[0] : null;
    },

    monthlyContribution(state) {
      let array = function (state) {
        if (
          state.contributionSavingsAccount &&
          "transactions" in state.contributionSavingsAccount &&
          Array.isArray(state.contributionSavingsAccount.transactions) &&
          state.contributionSavingsAccount.transactions.length > 0
        ) {
          return state.contributionSavingsAccount.transactions;
        }
        return [];
      };
      const transactions = array(state);

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.date;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + transaction.amount;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContribution(state) {
      let array = function (state) {
        if (
          state.contributionSavingsAccount &&
          "transactions" in state.contributionSavingsAccount &&
          Array.isArray(state.contributionSavingsAccount.transactions) &&
          state.contributionSavingsAccount.transactions.length > 0
        ) {
          return state.contributionSavingsAccount.transactions;
        }
        return [];
      };
      const transactions = array(state);

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.date;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + transaction.amount;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },
  },
};

export default savingsAccountApi;
