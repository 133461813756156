import apiHelperFactory from "@/http/apiHelperFactory";
// import helpers from "@/plugins/helpers";

const downloadTemplateApi = {
  namespaced: true,

  state: {
    // template
    template: null,
  },

  mutations: {
    // setTemplate
    setTemplate(state, payload) {
      state.template = payload;
    },

    // resetState
    resetState(state) {
      // template
      state.template = null;
    },
  },

  actions: {
    // downloadUsersTemplateReq
    async downloadUsersTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // locale
        if ("locale" in payload) {
          params.locale = payload.locale;
        }

        // dateFormat
        if ("dateFormat" in payload) {
          params.dateFormat = payload.dateFormat;
        }

        // downloadUsersTemplateRes
        const downloadUsersTemplateRes =
          await apiHelperFactory.downloadUsersTemplate(params);

        // setTemplate
        commit("setTemplate", downloadUsersTemplateRes.data);

        return downloadUsersTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    async downloadPaymentsTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // // locale
        // if ("locale" in payload) {
        //   params.locale = payload.locale;
        // }

        // // dateFormat
        // if ("dateFormat" in payload) {
        //   params.dateFormat = payload.dateFormat;
        // }

        // locale=en&dateFormat=dd MMMM yyyy&

        params.locale = "en";
        params.dateFormat = "dd MMMM yyyy";

        // downloadUsersTemplateRes
        const downloadPaymentsTemplateRes =
          await apiHelperFactory.downloadPaymentsTemplate(params);

        // setTemplate
        commit("setTemplate", downloadPaymentsTemplateRes.data);

        return downloadPaymentsTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // downloadClientsTemplateReq
    async downloadClientsTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // match OLS url:
        // https://finduis.softidoc.co.za/fineract-provider/api/v1/clients/downloadtemplate?tenantIdentifier=develop&locale=en&dateFormat=dd MMMM yyyy&&legalFormType=CLIENTS_PERSON

        // locale
        // if ("locale" in payload) {
        //   params.locale = payload.locale;
        // }

        // dateFormat
        // if ("dateFormat" in payload) {
        //   params.dateFormat = payload.dateFormat;
        // }

        // tenantIdentifier automatically added at end
        // params.tenantIdentifier = this.$store.state.auth.tenantIdentifier;

        params.locale = "en";

        // OLS backend url has && before legalFormType, but seems not necessary here
        // params.dateFormat = "dd%20MMMM%20yyyy&";

        params.dateFormat = "dd%20MMMM%20yyyy";
        params.legalFormType = "CLIENTS_PERSON";

        // downloadClientsTemplateRes
        const downloadClientsTemplateRes =
          await apiHelperFactory.downloadClientsTemplate(params);

        // setTemplate
        commit("setTemplate", downloadClientsTemplateRes.data);

        return downloadClientsTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // downloadLoansTemplateReq
    async downloadLoansTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // match OLS url:
        // https://finduis.softidoc.co.za/fineract-provider/api/v1/loans/downloadtemplate?tenantIdentifier=develop&locale=en&dateFormat=dd MMMM yyyy&

        // locale
        // if ("locale" in payload) {
        //   params.locale = payload.locale;
        // }

        // // dateFormat
        // if ("dateFormat" in payload) {
        //   params.dateFormat = payload.dateFormat;
        // }

        // tenantIdentifier automatically added at end
        // params.tenantIdentifier = this.$store.state.auth.tenantIdentifier;

        params.locale = "en";

        // OLS backend url has & after dateFormat, but seems not necessary here
        // params.dateFormat = "dd%20MMMM%20yyyy&";

        params.dateFormat = "dd MMMM yyyy";

        // downloadLoansTemplateRes
        const downloadLoansTemplateRes =
          await apiHelperFactory.downloadLoansTemplate(params);

        // setTemplate
        commit("setTemplate", downloadLoansTemplateRes.data);

        return downloadLoansTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // downloadSavingsAccountsTemplateReq
    async downloadSavingsAccountsTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // match OLS url:
        // https://finduis.softidoc.co.za/fineract-provider/api/v1/savingsaccounts/downloadtemplate?tenantIdentifier=develop&locale=en&dateFormat=dd MMMM yyyy&

        // locale
        // if ("locale" in payload) {
        //   params.locale = payload.locale;
        // }

        // dateFormat
        // if ("dateFormat" in payload) {
        //   params.dateFormat = payload.dateFormat;
        // }

        params.locale = "en";
        params.dateFormat = "dd MMMM yyyy";

        // downloadSavingsAccountsTemplateRes
        const downloadSavingsAccountsTemplateRes =
          await apiHelperFactory.downloadSavingsAccountsTemplate(params);

        // setTemplate
        commit("setTemplate", downloadSavingsAccountsTemplateRes.data);

        return downloadSavingsAccountsTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // downloadGLAccountsTemplateReq
    async downloadGLAccountsTemplateReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};

        // locale
        if ("locale" in payload) {
          params.locale = payload.locale;
        }

        // dateFormat
        if ("dateFormat" in payload) {
          params.dateFormat = payload.dateFormat;
        }

        // downloadGLAccountsTemplateRes
        const downloadGLAccountsTemplateRes =
          await apiHelperFactory.downloadGLAccountsTemplate(params);

        // setTemplate
        commit("setTemplate", downloadGLAccountsTemplateRes.data);

        return downloadGLAccountsTemplateRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getTemplate
    getTemplate(state) {
      return state.template;
    },
  },
};

export default downloadTemplateApi;
