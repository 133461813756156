import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";

const contributionApi = {
  namespaced: true,

  state: {
    // contributions
    contributions: [],
  },

  mutations: {
    // setContributions
    setContributions(state, payload) {
      state.contributions = payload;
    },

    // resetState
    resetState(state) {
      // contributions
      state.contributions = [];
    },
  },

  actions: {
    // getContributionsReq
    async getContributionsReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getContributionsRes
        const getContributionsRes = await apiHelperFactory.getContributions(
          payload.clientId
        );

        // setContributions
        commit("setContributions", getContributionsRes.data);

        return getContributionsRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createContributionReq
    async createContributionReq({ commit }, { payload, clientId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.createContribution(payload, clientId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateContributionReq
    async updateContributionReq(
      { commit },
      { payload, clientId, contributionId }
    ) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.updateContribution(
          payload,
          clientId,
          contributionId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // deleteContributionReq
    async deleteContributionReq({ commit }, { payload }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactory.deleteContribution(
          payload.clientId,
          payload.contributionId
        );
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    // getContributions
    getContributions(state) {
      return state.contributions;
    },

    // getContributionById
    getContributionById: (state) => (id) => {
      // contribution
      let contribution = state.contributions.find(
        (contribution) => contribution.id === id
      );

      return contribution ? contribution : null;
    },

    // getActiveContribution
    getActiveContribution(state) {
      // contribution
      let contribution = state.contributions.find(
        (contribution) => contribution.active === "true"
      );

      return contribution ? contribution : null;
    },

    // getActiveContribution
    getTotalContributionAmount(state) {
      // contribution
      let contribution = state.contributions.reduce(
        (sum, method) => sum + method.amount,
        0
      );
      return contribution;
    },

    monthlyContribution(state) {
      let array = function (state) {
        if (
          state.contributions &&
          Array.isArray(state.contributions) &&
          state.contributions.length > 0
        ) {
          return state.contributions;
        }
        return [];
      };
      const transactions = array(state);

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.created_at;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}-${month}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + transaction.amount;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed

        const resultArray = [];

        for (let i = 11; i >= 0; i--) {
          const year = currentMonth - i > 0 ? currentYear : currentYear - 1;
          const month = (currentMonth - i + 12) % 12 || 12;

          const key = `${year}-${month}`;

          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray;
    },

    yearlyContribution(state) {
      let array = function (state) {
        if (
          state.contributions &&
          Array.isArray(state.contributions) &&
          state.contributions.length > 0
        ) {
          return state.contributions;
        }
        return [];
      };
      const transactions = array(state);

      // Function to get the total amount for each month over the last 12 months
      function getTotalAmountByMonth(transactions) {
        const currentDate = new Date(); // Get the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

        const monthlyTotal = {};

        // Iterate over the transactions
        transactions.forEach((transaction) => {
          const [year, month] = transaction.created_at;

          // Check if the transaction is within the last 12 months
          if (
            year >= currentYear - 1 && // Transactions from the last year and current year
            ((year === currentYear - 1 && month >= currentMonth) || // For transactions in the last year, check the month
              (year === currentYear && month <= currentMonth)) // For transactions in the current year, check the month
          ) {
            const key = `${year}`;
            monthlyTotal[key] = (monthlyTotal[key] || 0) + transaction.amount;
          }
        });

        return monthlyTotal;
      }

      const result = getTotalAmountByMonth(transactions);

      function transformObjectToArray(obj) {
        const currentYear = new Date().getFullYear();
        const resultArray = [];

        for (let i = 0; i <= 7; i++) {
          const year = currentYear - i;
          const key = `${year}`;
          resultArray.push(obj[key] || 0);
        }

        return resultArray;
      }

      const inputObject = result;
      const resultArray = transformObjectToArray(inputObject);

      return resultArray.reverse();
    },
  },
};

export default contributionApi;
