import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";
import moment from "moment";

const inboxApiBank = {
  namespaced: true,

  state: {
    // Inbox
    inbox: [],
    // Messages
    messages: [],

    // users
    users: [],

    isSent: false,
    currentUser: {},

    // clients
    clients: [],
    email: "",
    documents: [],
    emailDocuments: [],
    userId: 0,
  },

  mutations: {
    // setInbox
    setInbox(state, payload) {
      state.inbox = payload;
    },
    // setInbox
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    // setInbox
    setIsSent(state, payload) {
      state.isSent = payload;
    },
    resetIsSent(state) {
      state.isSent = false;
    },
    setEmail(state, payload) {
      state.email = payload;
    },

    // resetInbox
    resetInbox(state) {
      state.inbox = [];
    },
    // setInbox
    setEmailDocuments(state, payload) {
      state.emailDocuments = payload;
    },

    // resetInbox
    resetEmailDocuments(state) {
      state.emailDocuments = [];
    },
    // setInbox
    setDocument(state, document) {
      state.documents.push(document);
    },

    // resetInbox
    resetDocument(state) {
      state.documents = [];
    },

    // setMessages
    setMessages(state, payload) {
      state.messages = payload;
    },

    // resetMessages
    resetMessages(state) {
      state.messages = [];
    },

    // setUsers
    setUsers(state, payload) {
      state.users = payload;
    },

    // setUserId
    setUserId(state, payload) {
      state.userId = payload;
    },

    // setClients
    setClients(state, payload) {
      state.clients = payload;
    },

    // resetState
    resetState(state) {
      // users
      state.users = [];
      state.messages = [];
      state.inbox = [];
      state.clients = [];
      // userId
      state.userId = 0;
      state.email = "";
      state.documents = [];
      state.emailDocuments = [];
      state.currentUser = {};
    },
  },

  actions: {
    // getMessagesReq
    async getMessagesReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getMessagesRes
        const getMessagesRes = await apiHelperFactoryForBank.getMessages(
          payload
        );

        // setMessages
        commit("setMessages", getMessagesRes.data);

        return getMessagesRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
    //getDocument

    async getDocumentReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getMessagesRes
        const getDocumentRes = await apiHelperFactoryForBank.getDocument(
          payload
        );
        console.log("getMessagesRes", getDocumentRes);
        // setMessages
        commit("setEmailDocuments", getDocumentRes.data);

        return getDocumentRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
    // createMessagesReq
    async createMessagesReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.createMessages(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateMassagesReq
    async updateMessagesReq({ commit }, { payload, MessagesId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.updateMessage(payload, MessagesId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getUsersReq
    async getUsersReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getUsersRes
        const getUsersRes = await apiHelperFactory.getUsers();

        // setUsers
        commit("setUsers", getUsersRes.data);

        return getUsersRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    async createInboxMessageReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.createMessagesDocument(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
    // getClientsReq
    async getClientsReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // params
        let params = {};
        params = payload;

        // getClientsRes
        const getClientsRes = await apiHelperFactory.getClients(params);

        // setClients
        commit("setClients", getClientsRes.data.pageItems);

        return getClientsRes;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // getInboxReq
    async getInboxReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // getInboxRes
        const getInboxRes = await apiHelperFactoryForBank.getInbox(payload);

        // setInbox
        commit("setInbox", getInboxRes.data);

        return getInboxRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // createInboxReq
    async createInboxReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.createInbox(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateMassagesReq
    async updateInboxReq({ commit }, { payload, InboxId }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        return await apiHelperFactoryForBank.updateInbox(payload, InboxId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getInbox
    getInbox(state) {
      return state.inbox;
    },
    getEmailDocument(state) {
      return state.emailDocuments;
    },
    // getMessages
    getMessages(state) {
      return state.messages;
    },

    // getMessages
    getUsers(state) {
      return state.users;
    },

    // get document
    getDocument(state) {
      return state.documents;
    },

    // getMessages
    getClients(state) {
      const filteredClients = state.clients.filter((message) => {
        return message.status.value === "Active";
      });

      return filteredClients;
    },

    // getMessages
    getInboxMessages(state, getters) {
      // const formattedDate = moment(inputDate).calendar(null, {
      //   sameDay: '[Today], H:mmA',
      //   nextDay: '[Tomorrow], H:mmA',
      //   nextWeek: 'dddd, H:mmA',
      //   lastDay: '[Yesterday], H:mmA',
      //   lastWeek: '[Last] dddd, H:mmA',
      //   sameElse: 'MMMM D, YYYY, H:mmA',
      // });

      // // If you're not using moment.js

      const findUserById = (userId) =>
        getters.getUsers.find((user) => user.id === userId);

      const findMessageById = (messageId) =>
        getters.getMessages.find((message) => message.id === messageId);

      const findDocumentById = (messageId) =>
        getters.getEmailDocument.find(
          (message) => message.messageId === messageId
        );

      const mappedInbox = getters.getInbox.map((inboxItem) => {
        const user = findUserById(inboxItem.senderId);
        const message = findMessageById(inboxItem.messageId);
        const document = findDocumentById(inboxItem.messageId);

        const formattedDate = moment(message.updatedAt)
          .add(2, "hours")
          .calendar(null, {
            sameDay: "[Today], H:mmA",
            nextDay: "[Tomorrow], H:mmA",
            nextWeek: "dddd, H:mmA",
            lastDay: "[Yesterday], H:mmA",
            lastWeek: "[Last] dddd, H:mmA",
            sameElse: "MMMM D, YYYY, H:mmA",
          });
        return {
          id: message.id,
          image:
            "https://thumbs.dreamstime.com/b/default-avatar-profile-flat-icon-social-media-user-vector-portrait-unknown-human-image-default-avatar-profile-flat-icon-184330869.jpg",
          name: user
            ? `${user ? user.firstname : ""} ${user ? user.lastname : ""}`
            : message.useranme,
          role:
            user && user.selectedRoles.length > 0
              ? user.selectedRoles[0].name
              : message.role,
          ccId: message.ccId,
          online: user ? user.isOnline : false,
          senderId: inboxItem.senderId,
          receiverId: inboxItem.receiverId,
          isDraft: message.isDraft,
          messageId: message.id,
          isRead: message.isRead,
          prevMessageId: message.prevMessageId,
          QueryType: message.queryType,
          date: formattedDate,
          email: user ? user.email : message.email,
          attachment: document ? document : false,
          message: message ? message.messages : "",
        };
      });

      return mappedInbox.reverse();
    },

    // getMessages
    getInboxMessagesAdmin(state, getters) {
      const findMessageById = (messageId) =>
        getters.getMessages.find((message) => message.id === messageId);

      const findDocumentById = (messageId) =>
        getters.getEmailDocument.find(
          (message) => message.messageId === messageId
        );

      const mappedInbox = getters.getInbox.map((inboxItem) => {
        const message = findMessageById(inboxItem.messageId);
        const document = findDocumentById(inboxItem.messageId);

        const formattedDate = moment(message.updatedAt)
          .add(2, "hours")
          .calendar(null, {
            sameDay: "[Today], H:mmA",
            nextDay: "[Tomorrow], H:mmA",
            nextWeek: "dddd, H:mmA",
            lastDay: "[Yesterday], H:mmA",
            lastWeek: "[Last] dddd, H:mmA",
            sameElse: "MMMM D, YYYY, H:mmA",
          });

        return {
          id: message.id,
          image:
            "https://thumbs.dreamstime.com/b/default-avatar-profile-flat-icon-social-media-user-vector-portrait-unknown-human-image-default-avatar-profile-flat-icon-184330869.jpg",
          name: message.useranme,
          role: message.role,
          ccId: message.ccId,
          online: false,
          senderId: inboxItem.senderId,
          receiverId: inboxItem.receiverId,
          QueryType: message.queryType,
          date: formattedDate,
          email: message.email,
          isRead: message.isRead,
          prevMessageId: message.prevMessageId,
          isDraft: message.isDraft,
          attachment: document ? document : false,
          message: message ? message.messages : "",
          status: message.status,
        };
      });

      return mappedInbox.reverse();
    },

    filteredMessages(state, getters, rootState) {
      const filteredArray = getters.getInboxMessages.filter((message) => {
        return (
          (message.receiverId === rootState.auth.userId &&
            message.isDraft === false) ||
          message.ccId === rootState.auth.userId
        );
      });
      return filteredArray.length;
    },

    getNumUnReadMessages(state, getters) {
      // const filteredArray = getters.getInbox.filter((item) => !item.isRead);

      const filteredArray = getters.getInboxMessagesAdmin.filter(
        (item) => item.QueryType === "System query" && !item.isRead
      );

      return filteredArray.length;
    },

    getInboxSystem(state, getters) {
      const filteredArray = getters.getInboxMessagesAdmin.filter(
        (item) => item.QueryType === "System query"
      );

      return filteredArray;
    },

    getInboxAdmin(state, getters) {
      const filteredArray = getters.getInboxMessagesAdmin.filter(
        (item) => item.QueryType === "Admin query"
      );

      return filteredArray;
    },

    getInboxMaintenance(state, getters) {
      const filteredArray = getters.getInboxMessagesAdmin.filter(
        (item) => item.QueryType === "Maintenance query"
      );

      return filteredArray;
    },

    getInboxMiscellaneous(state, getters) {
      const filteredArray = getters.getInboxMessagesAdmin.filter(
        (item) => item.QueryType === "Miscellaneous query"
      );

      return filteredArray;
    },

    getMes(state, getters) {
      const length =
        getters.getInboxMiscellaneous.length +
        getters.getInboxMaintenance.length +
        getters.getInboxAdmin.length +
        getters.getInboxSystem.length;

      return length;
    },
  },
};

export default inboxApiBank;
